import React from 'react'
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <div>
      <p>
        Sorry, this application cannot be used on its own. Please contact our
        support team for more information.
      </p>
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
)
